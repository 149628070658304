import React from 'react';

// External Components
import { Heading, Paragraph, Section } from '@thepuzzlers/pieces';

// Local Components
import { SectionSpacer } from 'components';

// Animations
import { revealStepElement } from './animations';

// Helper function
import { convertApiHtmlText } from 'sections/helper/convertApiHtmlText';
import { zIndex } from 'styled-system';

export const Steps = ({ cmsData }) => {
  return cmsData.process.map((process, index) => (
    <React.Fragment key={zIndex}>
      {index !== 0 && ( // index = 0 = false
        <SectionSpacer
          spacing={['54.56%', '38.78%', '28.28%', '13.95%', '22.02%', '23.45%']}
        />
      )}
      <ProcessBlock data={process} number={index} />
    </React.Fragment>
  ));
};

// Reused Components
const ProcessBlock = ({ data: { headline, description }, number }) => {
  // We use ref because we reuse this element with independent animation
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    return revealStepElement(sectionRef);
  }, []);

  return (
    <Section ref={sectionRef} className="process-block">
      <Paragraph
        className="process-block__number"
        type="xl"
        variant="bold"
        sx={{
          gridColumn: [
            '1/13',
            '1/ span 11',
            '1 / span 8',
            '1 / span 7',
            '2 / span 7',
            '3 / span 7'
          ],
          gridRow: [1, 1, 2, 2, 2, 2],
          mt: [null, null, '0.25em', '0.2em', '0.295em', '0.233em']
        }}>
        {`${number + 1}.`}
      </Paragraph>
      <Heading
        className="process-block__headline"
        as="h2"
        type="h2-200-110"
        variant="bold"
        sx={{
          gridColumn: [
            '1/ 13',
            '1/  span 11',
            '1/25',
            '1 / span 23',
            '2 / span 21',
            '3 / span 19'
          ],
          textTransform: 'uppercase',
          mt: ['1.33em', '0.74em', 0, 0, 0, 0]
        }}>
        {headline}
      </Heading>
      <Paragraph
        className="process-block__description"
        type="p-500-175"
        sx={{
          gridColumn: [
            '1/ 13',
            '1 /  span 11',
            '9 / span 15',
            '8 / span 15',
            '9 / span 13',
            '10 / span 12'
          ],
          mt: ['3.08em', '3.2em', '4.5em', '2.86em', '5.5em', '4.89em']
        }}
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(description.html)
        }}
      />
    </Section>
  );
};
