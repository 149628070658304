import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// TODO: Delete this revealStepElement if not needed anymore

export const revealStepElement = (sectionRef) => {
  const gsapSelector = gsap.utils.selector(sectionRef);
  const headline = gsapSelector('.process-block__headline')[0];
  const number = gsapSelector('.process-block__number')[0];
  const description = gsapSelector('.process-block__description')[0];

  let animation;

  const createStaggeredFadeInAnimation = (elements) => {
    ScrollTrigger.saveStyles(elements);
    animation = gsap.from(elements, {
      opacity: 0,
      y: 80,
      duration: 0.8,
      stagger: 0.3,
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top 80%'
      }
    });
    return () => {
      animation.kill();
      animation?.scrollTrigger?.kill();
    };
  };

  const phonePortraitAnimation = () => {
    return createStaggeredFadeInAnimation([number, headline, description]);
  };

  const bigScreenAnimation = () => {
    return createStaggeredFadeInAnimation([headline, number, description]);
  };

  ScrollTrigger.matchMedia({
    '(min-width: 1400px)': bigScreenAnimation,
    '(min-width: 1000px) and (max-width: 1399px)': bigScreenAnimation,
    '(min-width: 700px) and (max-width: 999px) and (orientation: landscape)':
      bigScreenAnimation,
    '(min-width: 750px) and (max-width: 999px) and (orientation: portrait)':
      bigScreenAnimation,
    '(max-width: 749px) and (orientation: portrait)': phonePortraitAnimation
  });

  return () => {
    if (animation) {
      animation?.kill();
      animation?.scrollTrigger?.kill();
    }
  };
};
