import { graphql, useStaticQuery } from 'gatsby';

// helper
import { useGetCurrentLanguageCmsData } from 'sections/helper';

export const useCmsProcessPageData = () => {
  const { getCurrentLanguageData } = useGetCurrentLanguageCmsData();

  const {
    allRestApiPublicPagesProcessEntries,
    allRestApiPublicContentTypesProcessitemEntries
  } = useStaticQuery(graphql`
    query {
      allRestApiPublicPagesProcessEntries {
        nodes {
          data {
            name
            key
            contentTypes {
              name
              key
              entries {
                id
                entry {
                  headline
                  description {
                    html
                  }
                  process {
                    id
                    data {
                      headline
                      description {
                        html
                      }
                    }
                  }
                }
                translations {
                  en {
                    headline
                    description {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
      allRestApiPublicContentTypesProcessitemEntries {
        nodes {
          data {
            id
            entry {
              headline
              description {
                html
              }
            }
            translations {
              en {
                headline
                description {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  const processPageSectionData =
    allRestApiPublicPagesProcessEntries.nodes[0].data.contentTypes;
  const processItems =
    allRestApiPublicContentTypesProcessitemEntries.nodes[0].data;

  const getProcessItemById = (id) =>
    processItems.find((processItem) => processItem.id === id);

  const mergedData = processPageSectionData.map((sectionData) => {
    if (sectionData.key !== 'processlist')
      return {
        ...sectionData,
        currentLanguageData: getCurrentLanguageData(sectionData.entries[0])
      };
    return {
      ...sectionData,
      currentLanguageData: {
        ...getCurrentLanguageData(sectionData.entries[0]),
        process: sectionData.entries[0].entry.process.map((processItem) => {
          return getCurrentLanguageData(getProcessItemById(processItem.id));
        })
      }
    };
  });

  return {
    header: mergedData[0],
    textBlock: mergedData[1],
    processList: mergedData[2]
  };
};
