import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  useProcessPageData,
  useCmsProcessPageData,
  Steps
} from 'sections/processPage';
import { SectionSpacer, ParagraphSection, HeadlineSection } from 'components';
import { useNavOverlayAnimation } from 'context/NavOverlayAnimationContext';

// Helper function
import { convertApiHtmlText } from 'sections/helper/convertApiHtmlText';

const Process = () => {
  const { seo } = useProcessPageData();
  const { isAnimating } = useNavOverlayAnimation();

  const {
    header: cmsHeader,
    textBlock: cmsTextBlock,
    processList: cmsProcess
  } = useCmsProcessPageData();
  if (!isAnimating)
    return (
      <>
        <SEO {...seo} />
        <HeadlineSection
          text={cmsHeader.currentLanguageData.headline}
          isFullHeight={true}
        />
        <ParagraphSection
          text={convertApiHtmlText(
            cmsTextBlock.currentLanguageData.description.html
          )}
        />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '24.03%', '18.76%']}
        />
        <Steps cmsData={cmsProcess.currentLanguageData} />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '40.04%', '31.26%']}
        />
      </>
    );
  return null;
};

export default injectIntl(Process);
